import { Controller } from "stimulus"
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = [ "form", "channel", "jobtype", "button" ]

  initialize() {
    var _this = this
    var rows = document.querySelectorAll("[id*='row_class']")
    rows.forEach(row => {
      row.closest("tr").classList.add(row.textContent.trim())
    })

    var cols = document.querySelectorAll("[data-attr*='row_class']")
    cols.forEach(col => {
      col.remove()
    })

    var cols = document.querySelectorAll("[id*='row_class']")
    cols.forEach(col => {
      col.remove()
    })

    var tbls = document.querySelectorAll("table.table-striped")
    tbls.forEach(tbl => {
      tbl.classList.remove('table-striped')
    })

  }

  row_click(event) {
    event.preventDefault()
    let url = this.element.getAttribute('data-click-url')
    Turbo.visit(url) // ต้องสั่งคำสั่งนี้ด้วย import { Turbo } from '@hotwired/turbo-rails'
  }

  // 2024-11-07 Added by Goong
  jobtype_change(event) {
    event.preventDefault()
    let channel = this.channelTarget.options[this.channelTarget.selectedIndex].text
    let jobtype = this.jobtypeTarget.options[this.jobtypeTarget.selectedIndex].text
    if ((channel == 'Agency') && (jobtype == 'งาน Pending')) {
      this.buttonTarget.setAttribute('data-toggle', "modal")
      this.buttonTarget.setAttribute('data-should-submit', "false")
    } else {
      this.buttonTarget.setAttribute('data-toggle', "")
      this.buttonTarget.setAttribute('data-should-submit', "true")
    }
  }
}
